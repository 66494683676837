.borderHeader {
    border: 1px solid #a51e36;
}

.borderBody {
    border: 2px solid #a51e36;
}

.marginContainerPeticion {
    margin-top: 79px!important;
}

.inputMargin {
    margin-bottom: 30px !important;
}

.marginLeft {
    margin-left: 8px !important;
}

.logo {
    text-align: center;
    height: 150px;
    width: 150px;
}
.marginFooter {
    margin-top: 181px !important;
}

.enviar{
    background-color: #a51e36 !important;
}

.title{
    text-align: center;
}


.size{
    font-size: 12px !important;
}