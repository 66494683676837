.h-100 {
  height: 100%!important;
}

.ml-auto, .mx-auto {
  margin-left: auto!important;
}

.mr-auto, .mx-auto {
  margin-right: auto!important;
}

.d-flex {
  display: flex!important;
}

.align-items-center {
  align-items: center!important;
}

.align-content-center {
  align-content: center!important;
}

.align-content-end {
  align-content: flex-end!important;
}

.justify-content-center {
  justify-content: center!important;
}

.text-right {
  text-align: right!important;
}

.text-left {
  text-align: left!important;
  display: flex;
  align-items: flex-start ;
  flex-direction: column;
}

.text-center {
  text-align: center!important;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.align-left {
  margin-left: 0;
}

.mr-05 {
  margin-right: 8px!important;
}
.ml-05 {
  margin-left: 8px!important;
}

.mr-1 {
  margin-right: 16px!important;
}

.mb-1 {
  margin-bottom: 16px!important;
}

.mb-2 {
  margin-bottom: 32px!important;
}

.mt-2 {
  margin-top: 32px!important;
}

.pb-0 {
  padding-bottom: 0!important;
}

.img-responsive {
  width: 100%;
  max-width: 100%;
}

.card-box {
  border-width: 1px;
  .card-footer {
    border-top: 1px solid rgba(122,123,151,.3);
    display: flex;
    padding: 16px;
    align-items: center;
    .MuiButton-root {
      margin-right: 8px;
    }
  }
  .card-header{
    border-bottom: 1px solid rgba(122,123,151,.3);
  }
  .MuiCardHeader-action {margin: 0;}
}

.maxWidth-md {
  max-width: 960px;
}

button span {
  pointer-events: none;
}

.primary {    color: #202648;}

.green {color: green;}
.disable {color: #c3c3c3;}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.TabPanel {
  overflow: visible;
}